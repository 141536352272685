import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const MainColumnWrapper = styled.div`
  margin: 0 auto;
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  max-width: ${props => props.theme.mainMaxWidth};

  @media ${device.desktop} {
    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};
  }
`
